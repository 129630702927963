import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";

type TableItem = {
	label: string;
	column: keyof TrackedItem;
};

type TrackedItem = {
	midwestAluminum: any;
	lmeAluminum: any;
	lmeNickel: any;
	lmeZinc: any;
	usMidwestHrc: any;
	stainlessSurcharge304: any;
	stainlessSurcharge316: any;
};

type ContentfulNode = {
	date: string;
	midwestAluminum: any;
	lmeAluminum: any;
	lmeNickel: any;
	lmeZinc: any;
	usMidwestHrc: any;
	stainlessSurcharge304: any;
	stainlessSurcharge316: any;
};

type MaterialMoversContent = {
	title: string;
	tagLine: string;
	description: string[];
	change: string;
};

const content: LanguageContent<MaterialMoversContent> = {
	en: {
		title: "Material Movers",
		tagLine: "Tracking the daily price change of raw materials critical to metal production",
		description: [
			"All prices represent the closing prices as of the date referenced above each column.",
			"Subscribe to receive the latest updates about metal and industry trends.",
		],
		change: "Change",
	},
	fr: {
		title: "Cours des matières premières",
		tagLine:
			"Suivre l'évolution quotidienne des prix des matières premières essentielles à la production de métaux",
		description: [
			"Tous les prix représentent les cours de clôture à la date indiquée au-dessus de chaque colonne.",
			"Abonnez-vous pour recevoir les dernières mises à jour sur les tendances du secteur des métaux.",
		],
		change: "Changement",
	},
	es: {
		title: "Movedores de materiales",
		tagLine:
			"Dar seguimiento al cambio diario de los precios de las materias primas es esencial para la producción de metales",
		description: [
			"Todos los precios representan los precios de cierre en la fecha de referencia encima de cada columna.",
			"Suscríbase para recibir las últimas actualizaciones acerca de las tendencias de metales y la industria.",
		],
		change: "Cambiar",
	},
};

const trackedItems: TableItem[] = [
	{
		label: "Midwest Aluminum",
		column: "midwestAluminum",
	},
	{
		label: "LME Aluminum",
		column: "lmeAluminum",
	},
	{
		label: "LME Nickel",
		column: "lmeNickel",
	},
	{
		label: "LME Zinc",
		column: "lmeZinc",
	},
	{
		label: "US Midwest HRC (USD/st)",
		column: "usMidwestHrc",
	},
	{
		label: "304 Stainless Surcharge",
		column: "stainlessSurcharge304",
	},
	{
		label: "316 Stainless Surcharge",
		column: "stainlessSurcharge316",
	},
];

const getLabel = (search: keyof TrackedItem) => {
	for (let i = 0; i < trackedItems.length; i++) {
		if (trackedItems[i].column === search) return trackedItems[i].label;
	}
	return null;
};

const Left = styled.div`
	display: inline-block;
	width: 280px;
	margin-right: 120px;
	vertical-align: top;
	height: auto;
`;

const Right = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 400px);
	height: auto;
`;

const Column = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 85px;
`;

const Row = styled.div`
	display: block;
	width: 100%;
	height: 60px;
`;

const MobileRow = styled.div`
	display: block;
	width: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
`;

const MobileHeaderRow = styled.div`
	display: block;
	width: 100%;
	padding: 7px 18px 7px 18px;
	box-sizing: border-box;
`;

const MobileColumn = styled.div`
	display: inline-block;
	width: 20%;
	vertical-align: top;
`;

const Divider = styled.hr`
	display: block;
	margin: 0px;
	opacity: 0.1;
`;

const MaterialMovers: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();

	const { theme } = useTheme();

	const queryData = useStaticQuery(graphql`
		query MaterialMoversQuery {
			allContentfulMaterialMovers(
				limit: 4
				sort: { fields: date, order: DESC }
				filter: { node_locale: { eq: "en-US" } }
			) {
				nodes {
					date
					lmeAluminum
					lmeNickel
					lmeZinc
					midwestAluminum
					stainlessSurcharge304
					stainlessSurcharge316
					usMidwestHrc
				}
			}
		}
	`);
	let midwestAluminumChangeGroup: any[] = [];
	let lmeAluminumChangeGroup: any[] = [];
	let lmeNickelChangeGroup: any[] = [];
	let lmeZincChangeGroup: any[] = [];
	let usMidwestHrcChangeGroup: any[] = [];
	let stainlessSurcharge304ChangeGroup: any[] = [];
	let stainlessSurcharge316ChangeGroup: any[] = [];
	let midwestAluminum: any[] = [];
	let lmeAluminum: any[] = [];
	let lmeNickel: any[] = [];
	let lmeZinc: any[] = [];
	let usMidwestHrc: any[] = [];
	let stainlessSurcharge304: any[] = [];
	let stainlessSurcharge316: any[] = [];
	let dates: any[] = [];
	queryData.allContentfulMaterialMovers.nodes.forEach((node: ContentfulNode, index: number) => {
		dates.push(node.date);
		midwestAluminum.push(node.midwestAluminum);
		lmeAluminum.push(node.lmeAluminum);
		lmeNickel.push(node.lmeNickel);
		lmeZinc.push(node.lmeZinc);
		usMidwestHrc.push(node.usMidwestHrc);
		stainlessSurcharge304.push(node.stainlessSurcharge304);
		stainlessSurcharge316.push(node.stainlessSurcharge316);
		if (midwestAluminumChangeGroup.length !== 2 && node.midwestAluminum !== null) {
			midwestAluminumChangeGroup.push(node.midwestAluminum);
		}
		if (lmeAluminumChangeGroup.length !== 2 && node.lmeAluminum !== null) {
			lmeAluminumChangeGroup.push(node.lmeAluminum);
		}
		if (lmeNickelChangeGroup.length !== 2 && node.lmeNickel !== null) {
			lmeNickelChangeGroup.push(node.lmeNickel);
		}
		if (lmeZincChangeGroup.length !== 2 && node.lmeZinc !== null) {
			lmeZincChangeGroup.push(node.lmeZinc);
		}
		if (usMidwestHrcChangeGroup.length !== 2 && node.usMidwestHrc !== null) {
			usMidwestHrcChangeGroup.push(node.usMidwestHrc);
		}
		if (stainlessSurcharge304ChangeGroup.length !== 2 && node.stainlessSurcharge304 !== null) {
			stainlessSurcharge304ChangeGroup.push(node.stainlessSurcharge304);
		}
		if (stainlessSurcharge316ChangeGroup.length !== 2 && node.stainlessSurcharge316 !== null) {
			stainlessSurcharge316ChangeGroup.push(node.stainlessSurcharge316);
		}
	});

	const getChange = (changeGroup: any[]) => {
		if (changeGroup.length === 2) {
			const newest = changeGroup[0];
			const oldest = changeGroup[1];
			if (newest === oldest) return null;
			if (newest < oldest) {
				let percentage = (1 - parseFloat(newest) / parseFloat(oldest)) * -100;
				if (percentage < 0) return percentage.toFixed(2) + "%";
				else return null;
			}
			if (newest > oldest) {
				let percentage = (1 - parseFloat(oldest) / parseFloat(newest)) * 100;
				if (percentage > 0) return percentage.toFixed(2) + "%";
				else return null;
			}
		} else {
			return null;
		}
	};
	const formatDate = (dateString: string) => {
		let months: LanguageContent<string[]> = {
			en: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			fr: [
				"jan",
				"fév",
				"mar",
				"avr",
				"mai",
				"juin",
				"juil",
				"aoû",
				"sep",
				"oct",
				"nov",
				"déc",
			],
			es: [
				"ene",
				"deb",
				"mar",
				"abr",
				"may",
				"jun",
				"jul",
				"ago",
				"sep",
				"oct",
				"nov",
				"dic",
			],
		};
		let date = new Date(dateString);
		let dateMS = date.getTime();
		let now = new Date();

		let month = months[language][date.getMonth()];
		let day = date.getDate();
		return day + "-" + month + (dateMS > now.getTime() ? " est." : "");
	};
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Left
					css={css`
						@media (max-width: ${theme.breakpoints.xl}) {
							margin-right: 20px;
						}
					`}
				>
					<Typography
						variant="h2"
						type="primary"
						css={css`
							letter-spacing: -2px;
							margin-bottom: 50px;
						`}
					>
						{content[language].title}
					</Typography>
					<Typography
						variant="h4"
						css={css`
							font-size: 20px;
							letter-spacing: -1px;
							margin-bottom: 50px;
						`}
						color={theme.colors.primary.darkGray}
					>
						{content[language].tagLine}
					</Typography>
					{content[language].description.map((desc: string, index: number) => {
						return (
							<Typography
								key={index}
								variant="div"
								type="primary"
								size="md"
								css={css`
									margin-bottom: 18px;
								`}
							>
								{desc}
							</Typography>
						);
					})}
				</Left>
				<Right
					css={css`
						@media (max-width: ${theme.breakpoints.xl}) {
							width: calc(100% - 300px);
						}
					`}
				>
					<Column
						css={css`
							width: calc(100% - 451px);
							border-left: 1px solid ${theme.colors.primary.lighterGray};
							border-top: 1px solid ${theme.colors.primary.lighterGray};
							border-bottom: 1px solid ${theme.colors.primary.lighterGray};
							border-radius: 2px 0 0 2px;
							@media (max-width: ${theme.breakpoints.xl}) {
								width: calc(100% - 435px);
							}
						`}
					>
						<Row
							css={css`
								border-radius: 2px 0 0 0;
								background-color: ${theme.colors.primary.lighterGray};
							`}
						/>
						{trackedItems.map((item: TableItem, index: number) => {
							return (
								<Row
									key={index}
									css={css`
										margin-left: 26px;
										width: calc(100% - 26px);
										border-bottom: 1px solid ${theme.colors.primary.lighterGray};
										border-radius: ${trackedItems.length - 1 === index
											? "0 0 0 2px"
											: "0 0 0 0"};
										@media (max-width: ${theme.breakpoints.xl}) {
											width: calc(100% - 10px);
											margin-left: 10px;
										}
									`}
								>
									<Typography
										variant="div"
										color={theme.colors.primary.header}
										size="md"
										css={css`
											line-height: 60px;
											@media (max-width: ${theme.breakpoints.xl}) {
												font-size: 14px;
											}
										`}
									>
										{item.label}
									</Typography>
								</Row>
							);
						})}
					</Column>
					{queryData.allContentfulMaterialMovers.nodes.map(
						(item: ContentfulNode, index: number) => {
							return (
								<Column
									key={index}
									css={css`
										border-top: 1px solid ${theme.colors.primary.lighterGray};
										border-bottom: 1px solid ${theme.colors.primary.lighterGray};
									`}
								>
									<Row
										css={css`
											background-color: ${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="sm"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{formatDate(item.date)}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.midwestAluminum ?? "–"}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.lmeAluminum ?? "–"}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.lmeNickel ?? "–"}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.lmeZinc ?? "–"}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.usMidwestHrc ?? "–"}
										</Typography>
									</Row>
									<Row
										css={css`
											border-bottom: 1px solid
												${theme.colors.primary.lighterGray};
										`}
									>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.stainlessSurcharge304 ?? "–"}
										</Typography>
									</Row>
									<Row>
										<Typography
											variant="div"
											size="md"
											css={css`
												line-height: 60px;
											`}
											color={theme.colors.primary.header}
										>
											{item.stainlessSurcharge316 ?? "–"}
										</Typography>
									</Row>
								</Column>
							);
						}
					)}
					<Column
						css={css`
							border-right: 1px solid ${theme.colors.primary.lighterGray};
							border-top: 1px solid ${theme.colors.primary.lighterGray};
							border-bottom: 1px solid ${theme.colors.primary.lighterGray};
							border-radius: 0 2px 2px 0;
							width: 111px;
							@media (max-width: ${theme.breakpoints.xl}) {
								width: 95px;
							}
						`}
					>
						<Row
							css={css`
								background-color: ${theme.colors.primary.lighterGray};
								border-radius: 0 2px 0 0;
							`}
						>
							<Typography
								variant="div"
								size="sm"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{content[language].change}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(midwestAluminumChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(lmeAluminumChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(lmeNickelChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(lmeZincChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(usMidwestHrcChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								border-bottom: 1px solid ${theme.colors.primary.lighterGray};
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(stainlessSurcharge304ChangeGroup) ?? "–"}
							</Typography>
						</Row>
						<Row
							css={css`
								margin-right: 26px;
								width: calc(100% - 26px);
								@media (max-width: ${theme.breakpoints.xl}) {
									width: calc(100% - 10px);
									margin-right: 10px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								css={css`
									line-height: 60px;
								`}
								color={theme.colors.primary.header}
							>
								{getChange(stainlessSurcharge316ChangeGroup) ?? "–"}
							</Typography>
						</Row>
					</Column>
				</Right>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="secondary" vPadding="42px">
					<Typography
						variant="h3"
						type="secondary"
						css={css`
							margin-bottom: 26px;
							letter-spacing: -1px;
						`}
					>
						{content[language].title}
					</Typography>
					<Typography
						variant="div"
						weight="bolder"
						size="lg"
						css={css`
							margin-bottom: 16px;
						`}
						type="secondary"
						color={theme.colors.primary.darkGray}
					>
						{content[language].tagLine}
					</Typography>
					{content[language].description.map((desc: string, index: number) => {
						return (
							<Typography
								key={index}
								type="secondary"
								size="md"
								variant="div"
								css={css`
									margin-bottom: ${content[language].description.length - 1 ===
									index
										? "0px"
										: "12px"};
								`}
							>
								{desc}
							</Typography>
						);
					})}
				</ContentSection>
				<ContentSection vPadding="0" hPadding="0" type="primary">
					<MobileHeaderRow
						css={css`
							background-color: ${theme.colors.tertiary.background};
						`}
					>
						{dates.map((date: string, index: number) => {
							return (
								<MobileColumn key={index}>
									<Typography
										variant="div"
										color={theme.colors.tertiary.header}
										type="tertiary"
										size="sm"
									>
										{formatDate(date)}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography
								variant="div"
								type="tertiary"
								color={theme.colors.tertiary.header}
								size="sm"
							>
								{content[language].change}
							</Typography>
						</MobileColumn>
					</MobileHeaderRow>
					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("midwestAluminum")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{midwestAluminum.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(midwestAluminumChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>
					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("lmeAluminum")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{lmeAluminum.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(lmeAluminumChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>

					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("lmeNickel")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{lmeNickel.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(lmeNickelChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>

					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("lmeZinc")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{lmeZinc.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(lmeZincChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>

					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("usMidwestHrc")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{usMidwestHrc.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(usMidwestHrcChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>
					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("stainlessSurcharge304")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{stainlessSurcharge304.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(stainlessSurcharge304ChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>
					<MobileRow
						css={css`
							background-color: ${theme.colors.secondary.background};
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.secondary.header}
							type="secondary"
							size="md"
						>
							{getLabel("stainlessSurcharge316")}
						</Typography>
					</MobileRow>
					<MobileRow>
						{stainlessSurcharge316.map((item: any, index: number) => {
							return (
								<MobileColumn
									css={css`
										background-color: ${theme.colors.primary.background};
									`}
									key={index}
								>
									<Typography
										variant="div"
										size="sm"
										color={theme.colors.primary.header}
									>
										{item ?? "–"}
									</Typography>
								</MobileColumn>
							);
						})}
						<MobileColumn>
							<Typography variant="div" size="sm" color={theme.colors.primary.header}>
								{getChange(stainlessSurcharge316ChangeGroup) ?? "–"}
							</Typography>
						</MobileColumn>
					</MobileRow>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
				</ContentSection>
			</Media>
		</>
	);
};

export default MaterialMovers;
